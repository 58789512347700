.avatar {
  border-radius: 9999px;
  background-color: rgb(212, 188, 245);
  overflow: hidden;
  width: 2.25rem;
  height: 2.25rem;
}

.avatar > img {
  width: 100%;
  height: 100%;
}
